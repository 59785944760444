import Newsletter from "../../assets/images/newsletter/newsletter.webp";
import FramerScrollDown from "../../framer-effects/framer-scroll-down";

function HomeNews() {
    return (
        <div className="grid grid-cols-1 items-center justify-center w-full divide-lapooLichtGroen/50 gap-8
                        xl:grid-cols-2 xl:divide-x-2">
            <FramerScrollDown className="flex flex-col-reverse items-center justify-center w-full gap-4
                            xl:flex-row">
                <div className="flex flex-row w-full items-center justify-center gap-4">
                    <a target="_blank" className="flex flex-col items-center justify-center gap-2 hover:-translate-y-1 transition-all duration-200 group" href="https://www.facebook.com/schoonheidsinstituutlapoo/" rel="noreferrer">
                        <div className="flex flex-col items-center justify-center text-white border-4 border-lapooGroen rounded-full p-4 w-fit mx-auto bg-lapooLichtGroen shadow-lg group-hover:shadow-2xl">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-auto h-16 sm:h-20 lg:h-24" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
                            </svg>
                        </div>
                    </a>
                    <a target="_blank" className="flex flex-col items-center justify-center gap-2 hover:-translate-y-1 transition-all duration-200 group" href="https://www.instagram.com/schoonheidsinstituut_lapoo/" rel="noreferrer">
                        <div className="flex flex-col items-center justify-center text-white border-4 border-lapooGroen rounded-full p-4 w-fit mx-auto bg-lapooLichtGroen shadow-lg group-hover:shadow-2xl">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-auto h-16 sm:h-20 lg:h-24" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <rect x="4" y="4" width="16" height="16" rx="4"></rect>
                                <circle cx="12" cy="12" r="3"></circle>
                                <line x1="16.5" y1="7.5" x2="16.5" y2="7.501"></line>
                            </svg>
                        </div>
                    </a>
                </div>
                <div className="flex flex-col w-full items-center justify-center gap-4">
                    <h2 className="text-lg highlight">Sociale media</h2>
                    <p className="w-2/3 text-base text-center">
                        Volg ons op Facebook en Instagram om mee te doen
                        aan winacties en op de hoogte te blijven van onze acties.
                    </p>
                </div>
            </FramerScrollDown >
            <FramerScrollDown className="flex flex-col items-center justify-center w-full gap-4
                            xl:flex-row">
                <div className="flex flex-col w-full items-center justify-center gap-4">
                    <h2 className="text-lg highlight">Nieuwsbrief</h2>
                    <p className="w-2/3 text-base text-center">
                        Schrijf je in voor onze nieuwsbrief en
                        blijf op de hoogte van onze acties van de maand.
                    </p>
                    <a target="_blank" className="px-5 py-2.5 relative rounded group text-white inline-block hover:-translate-y-1 transition-all duration-200"
                        href="https://schoonheidsinstituut-lapoo.us20.list-manage.com/subscribe/post?u=7db3a068cdd99246a43da653c&amp;id=495cb71d90&amp;f_id=00fc4fe6f0" rel="noreferrer">
                        <span className="absolute top-0 left-0 w-full h-full rounded opacity-50 filter blur-sm bg-gradient-to-br from-lapooLichtGroen to-lapooLichtGroen"></span>
                        <span className="h-full w-full inset-0 absolute mt-0.5 ml-0.5 bg-gradient-to-br filter group-active:opacity-0 rounded opacity-50 from-lapooLichtGroen to-lapooLichtGroen"></span>
                        <span className="absolute inset-0 w-full h-full transition-all duration-200 ease-out rounded shadow-xl bg-gradient-to-br filter group-active:opacity-0 group-hover:blur-sm from-lapooLichtGroen to-lapooLichtGroen"></span>
                        <span className="absolute inset-0 w-full h-full transition duration-200 ease-out rounded bg-gradient-to-br to-lapooLichtGroen from-lapooLichtGroen"></span>
                        <span className="relative flex flex-row gap-2">
                            Schrijf je in!
                        </span>
                    </a>
                </div>
                <div className="flex flex-col w-full items-center justify-center">
                    <img className="w-full sm:max-w-[50%] xl:max-w-[70%]" src={Newsletter} alt="Nieuwsbrief vector art" />
                </div>
            </FramerScrollDown>
        </div>
    );
}

export default HomeNews;