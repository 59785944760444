import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

type Inputs = {
    voornaam: string,
    achternaam: string,
    email: string,
    telefoon: string,
    bericht: string,
    access_key: string,
    botcheck: boolean,
    from_name: string,
    subject: string,
};

function ContactUsForm() {
    const { register, handleSubmit, reset, formState: { errors, isSubmitSuccessful } } = useForm<Inputs>({ mode: "onTouched" });
    const [isSuccess, setIsSuccess] = useState(false);

    const onSubmit: SubmitHandler<Inputs> = async (data, e) => {
        data.subject = `${data.voornaam} ${data.achternaam} heeft een bericht gestuurd via de website.`;

        await fetch("https://api.web3forms.com/submit", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(data, null, 2),
        })
            .then(async (response) => {
                let json = await response.json();
                if (json.success) {
                    setIsSuccess(true);
                    e!.target.reset();
                    reset();
                } else {
                    setIsSuccess(false);
                }
            })
            .catch((error) => {
                setIsSuccess(false);
                console.log(error);
            });
    };

    return (
        <>
            <div className="w-full flex flex-col-reverse items-center justify-center min-h-[50vh] text-base my-24
                lg:w-3/4 lg:flex-row">
                <div className="flex flex-col w-full items-center justify-center m-4 gap-2
                    lg:w-1/2
                    lg:items-start">
                    <h1 className="font-bold text-xl text-center">Contactgegevens</h1>
                    <div className="flex flex-row w-full items-center justify-center lg:justify-start gap-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
                        </svg>
                        <p className="whitespace-nowrap">0474/67 80 26</p>
                    </div>
                    <div className="flex flex-row w-full items-center justify-center lg:justify-start gap-4">
                        <svg xmlns="http://www.w3.org/2000/svg"  width="36" height="36" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <circle cx="12" cy="11" r="3"></circle>
                            <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
                        </svg>
                        <p>Mezenstraat 15, 9070 Destelbergen</p>
                    </div>
                    <div className="flex flex-row w-full items-center justify-center lg:justify-start gap-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <rect x="3" y="5" width="18" height="14" rx="2"></rect>
                            <polyline points="3 7 12 13 21 7"></polyline>
                        </svg>
                        <p className="whitespace-nowrap">lapoo@skynet.be</p>
                    </div>

                    <div className="flex flex-row w-full items-center justify-center lg:justify-start gap-4">
                        <a target="_blank" className="hover:-translate-y-1 transition-all duration-200 hover:text-lapooLichtGroen" href="https://www.facebook.com/schoonheidsinstituutlapoo/" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
                            </svg>
                        </a>
                        <a target="_blank" className="hover:-translate-y-1 transition-all duration-200 hover:text-lapooLichtGroen" href="https://www.instagram.com/schoonheidsinstituut_lapoo/" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <rect x="4" y="4" width="16" height="16" rx="4"></rect>
                            <circle cx="12" cy="12" r="3"></circle>
                            <line x1="16.5" y1="7.5" x2="16.5" y2="7.501"></line>
                        </svg>
                        </a>
                    </div>

                    <div className="mt-8 bg-gray-100 px-8 py-4">
                        <h1 className="font-bold text-xl text-center mb-4">Openingsuren</h1>
                        <div className="flex flex-col w-full items-center justify-center">
                            <div className="flex flex-row items-center justify-center lg:justify-start w-full">
                                <p className="mr-4 min-w-[100px]">Maandag:</p>
                                <p>08:00 - 20:00</p>
                            </div>
                            <div className="flex flex-row items-center justify-center lg:justify-start w-full">
                                <p className="mr-4 min-w-[100px]">Dinsdag:</p>
                                <p>08:00 - 20:00</p>
                            </div>
                            <div className="flex flex-row items-center justify-center lg:justify-start w-full">
                                <p className="mr-4 min-w-[100px]">Woensdag:</p>
                                <p>08:00 - 20:00</p>
                            </div>
                            <div className="flex flex-row items-center justify-center lg:justify-start w-full">
                                <p className="mr-4 min-w-[100px]">Donderdag:</p>
                                <p>08:00 - 20:00</p>
                            </div>
                            <div className="flex flex-row items-center justify-center lg:justify-start w-full">
                                <p className="mr-4 min-w-[100px]">Vrijdag:</p>
                                <p>08:00 - 18:00</p>
                            </div>
                            <div className="flex flex-row items-center justify-center lg:justify-start w-full">
                                <p className="mr-4 min-w-[100px]">Zaterdag:</p>
                                <p>gesloten</p>
                            </div>
                            <div className="flex flex-row items-center justify-center lg:justify-start w-full">
                                <p className="mr-4 min-w-[100px]">Zondag:</p>
                                <p>gesloten</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-3/4 items-center justify-center m-4 gap-2
                    lg:w-1/2
                    lg:items-start">
                    {!isSubmitSuccessful && (
                        <form className="flex flex-col w-full shadow-2xl p-8 gap-8" onSubmit={handleSubmit(onSubmit)}>
                            { /** WEB3FORMS CONFIG */}
                            <input type="hidden" value="d5aada3a-4d00-4808-ba76-bf0267a5b78b" {...register("access_key")}></input>
                            <input type="checkbox" id="" style={{ display: "none" }} className="hidden" {...register("botcheck")}></input>
                            <input type="hidden" value="Lapoo website" {...register("from_name")}></input>
                            <input type="hidden" {...register("subject")}></input>

                            <div className="flex flex-col w-full items-center justify-center gap-2">
                                <h1 className="font-bold text-xl w-full text-left">Stuur een bericht</h1>
                                <p className="text-base">
                                    <span className="text-red-500">Let op!</span> Een afspraak maken of annuleren kan <b>enkel telefonisch!</b> Heb je nog andere vragen of opmerkingen?
                                    Vul dan het onderstaande formulier in en wij zullen u zo snel mogelijk antwoorden.
                                </p>
                            </div>
                            <div className="flex flex-col w-full gap-4
                                            lg:flex-row">
                                <div className="flex flex-col w-full gap-1
                                                lg:w-1/2">
                                    <label className="after:content-['*'] after:ml-0.5 after:text-red-500" htmlFor="voornaam">Voornaam</label>
                                    <input type="text" className="bg-gray-50 h-10 border-gray-300 focus:ring-0 focus:border-lapooLichtGroen" {...register("voornaam", { required: true, maxLength: 35 })}></input>
                                    {errors.voornaam?.type === 'required' && <span className="text-red-500">Dit veld is verplicht</span>}
                                    {errors.voornaam?.type === 'maxLength' && <span className="text-red-500">Maximum van 35 letters bereikt</span>}
                                </div>
                                <div className="flex flex-col w-full gap-1
                                                lg:w-1/2">
                                    <label className="after:content-['*'] after:ml-0.5 after:text-red-500" htmlFor="achternaam">Achternaam</label>
                                    <input type="text" className="bg-gray-50 h-10 border-gray-300 focus:ring-0 focus:border-lapooLichtGroen" {...register("achternaam", { required: true, maxLength: 35 })}></input>
                                    {errors.achternaam?.type === 'required' && <span className="text-red-500">Dit veld is verplicht</span>}
                                    {errors.achternaam?.type === 'maxLength' && <span className="text-red-500">Maximum van 35 letters bereikt</span>}
                                </div>
                            </div>

                            <div className="flex flex-col w-full gap-4
                                            lg:flex-row">
                                <div className="flex flex-col w-full gap-1
                                                lg:w-1/2">
                                    <label className="after:content-['*'] after:ml-0.5 after:text-red-500" htmlFor="email">Email
                                    </label>
                                    <input type="email" className="bg-gray-50 h-10 border-gray-300 focus:ring-0 focus:border-lapooLichtGroen" {...register("email", { required: true, pattern: /[^@\s]+@[^@\s]+\.[^@\s]+/ , maxLength: 50})}></input>
                                    {errors.email?.type === 'required' && <span className="text-red-500">Dit veld is verplicht</span>}
                                    {errors.email?.type === 'pattern' && <span className="text-red-500">Geef een geldig email adres in</span>}
                                    {errors.email?.type === 'maxLength' && <span className="text-red-500">Maximum van 50 karakters bereikt</span>}
                                </div>
                                <div className="flex flex-col w-full gap-1
                                                lg:w-1/2">
                                    <label className="after:content-['*'] after:ml-0.5 after:text-red-500" htmlFor="telefoon">Telefoon
                                    </label>
                                    <input type="tel" className="bg-gray-50 h-10 border-gray-300 focus:ring-0 focus:border-lapooLichtGroen" {...register("telefoon", { required: true, pattern: /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/ , maxLength: 15})}></input>
                                    {errors.telefoon?.type === 'required' && <span className="text-red-500">Dit veld is verplicht</span>}
                                    {errors.telefoon?.type === 'pattern' && <span className="text-red-500">Geef een geldig telefoonnummer in</span>}
                                    {errors.telefoon?.type === 'maxLength' && <span className="text-red-500">Maximum van 15 karakters bereikt</span>}
                                </div>
                            </div>

                            <div className="flex flex-col w-full gap-1">
                                <label className="after:content-['*'] after:ml-0.5 after:text-red-500" htmlFor="bericht">Bericht</label>
                                <textarea className="bg-gray-50 h-44 border-gray-300 focus:ring-0 focus:border-lapooLichtGroen" {...register("bericht", { required: true, maxLength: 500 })}></textarea>
                                {errors.bericht?.type === 'required' && <span className="text-red-500">Dit veld is verplicht</span>}
                                {errors.bericht?.type === 'maxLength' && <span className="text-red-500">Maximum van 500 karakters bereikt</span>}
                            </div>
                            <div>
                                <input className="rounded-sm bg-lapooLichtGroen text-white hover:bg-lapooGroen px-4 py-2" type="submit"></input>
                            </div>
                        </form>
                    )}
                    {isSubmitSuccessful && isSuccess && (
                        <>
                            <div className="flex flex-col items-center justify-center text-center text-white rounded-md w-full">
                                <svg
                                    width="100"
                                    height="100"
                                    className="text-green-300"
                                    viewBox="0 0 100 100"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M26.6666 50L46.6666 66.6667L73.3333 33.3333M50 96.6667C43.8716 96.6667 37.8033 95.4596 32.1414 93.1144C26.4796 90.7692 21.3351 87.3317 17.0017 82.9983C12.6683 78.6649 9.23082 73.5204 6.8856 67.8586C4.54038 62.1967 3.33331 56.1283 3.33331 50C3.33331 43.8716 4.54038 37.8033 6.8856 32.1414C9.23082 26.4796 12.6683 21.3351 17.0017 17.0017C21.3351 12.6683 26.4796 9.23084 32.1414 6.88562C37.8033 4.5404 43.8716 3.33333 50 3.33333C62.3767 3.33333 74.2466 8.24998 82.9983 17.0017C91.75 25.7534 96.6666 37.6232 96.6666 50C96.6666 62.3768 91.75 74.2466 82.9983 82.9983C74.2466 91.75 62.3767 96.6667 50 96.6667Z"
                                        stroke="currentColor"
                                        strokeWidth="3"
                                    />
                                </svg>
                                <h3 className="py-5 text-2xl text-green-500">Wij hebben uw bericht ontvangen!</h3>
                            </div>
                        </>
                    )}

                    {isSubmitSuccessful && !isSuccess && (
                        <div className="flex flex-col items-center justify-center text-center text-white rounded-md">
                            <svg
                                width="97"
                                height="97"
                                viewBox="0 0 97 97"
                                className="text-red-400"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M27.9995 69C43.6205 53.379 52.3786 44.621 67.9995 29M26.8077 29L67.9995 69M48.2189 95C42.0906 95 36.0222 93.7929 30.3604 91.4477C24.6985 89.1025 19.554 85.6651 15.2206 81.3316C10.8872 76.9982 7.44975 71.8538 5.10454 66.1919C2.75932 60.53 1.55225 54.4617 1.55225 48.3333C1.55225 42.205 2.75932 36.1366 5.10454 30.4748C7.44975 24.8129 10.8872 19.6684 15.2206 15.335C19.554 11.0016 24.6985 7.56418 30.3604 5.21896C36.0222 2.87374 42.0906 1.66667 48.2189 1.66667C60.5957 1.66667 72.4655 6.58333 81.2172 15.335C89.9689 24.0867 94.8856 35.9566 94.8856 48.3333C94.8856 60.7101 89.9689 72.58 81.2172 81.3316C72.4655 90.0833 60.5957 95 48.2189 95Z"
                                    stroke="CurrentColor"
                                    strokeWidth="3"
                                />
                            </svg>

                            <h3 className="text-2xl text-red-400 py-4">
                                Oeps, er is iets verkeerd gelopen!
                            </h3>
                            <button className="px-4 py-2 focus:outline-none text-white bg-red-400 rounded-lg" onClick={() => reset()}>
                                Probeer opnieuw
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex flex-col items-center justify-center relative text-right w-full">
                <div className="overflow-hidden bg-none h-[500px] w-full">
                    <iframe className="h-full w-full" title="gmaps" id="gmap_canvas" src="https://maps.google.com/maps?q=Mezenstraat%2015&t=&z=15&ie=UTF8&iwloc=&output=embed">
                    </iframe>
                </div>
            </div>
        </>
    );
}

export default ContactUsForm;