import CadeaubonForm from "../components/cadeaubon/cadeaubon-form";
import CadeaubonHero from "../components/cadeaubon/hero/cadeaubon-hero";
import HeroSectionContainer from "../shared/components/section-container/hero-section-container";
import SectionContainer from "../shared/components/section-container/section-container";

function CadeaubonPage() {
    return (
        <div className="flex flex-col items-center w-full">
            <HeroSectionContainer>
                <CadeaubonHero />
            </HeroSectionContainer>
            <SectionContainer>
                <CadeaubonForm />
            </SectionContainer>
        </div>
    );
}

export default CadeaubonPage;