import { motion } from "framer-motion";
function FramerNavbarItem({ children, className, isActive }: { children?: any, className?: string, isActive?: boolean } = {}) {
    
    const activeLinkClassName = "block w-full h-0.5 bg-lapooLichtGroen transition-all duration-300";

    const inactiveLinkClassName = "block w-0 h-0.5 bg-lapooLichtGroen group-hover:w-full transition-all duration-300";

    return (
        <motion.li
            className={
                isActive
                ? "text-lapooGroen group"
                : "hover:text-lapooGroen group"
            }
        >
            {children}
            <motion.span
                className={
                    isActive 
                    ? activeLinkClassName 
                    : inactiveLinkClassName}
                layout
            >
            </motion.span>
        </motion.li>
    );
}

export default FramerNavbarItem;