import ImageGallery from "../components/image-gallery/image-gallery";
import HeroSectionContainer from "../shared/components/section-container/hero-section-container";
import instituut1 from "../assets/images/gallerij-instituut/instituut1.webp";
import instituut2 from "../assets/images/gallerij-instituut/instituut2.webp";
import instituut3 from "../assets/images/gallerij-instituut/instituut3.webp";
import instituut4 from "../assets/images/gallerij-instituut/instituut4.webp";
import instituut5 from "../assets/images/gallerij-instituut/instituut5.webp";
import instituut6 from "../assets/images/gallerij-instituut/instituut6.webp";

import behandelingen1 from "../assets/images/gallerij-behandelingen/behandelingen1.webp";
import behandelingen2 from "../assets/images/gallerij-behandelingen/behandelingen2.webp";
import behandelingen3 from "../assets/images/gallerij-behandelingen/behandelingen3.webp";
import behandelingen4 from "../assets/images/gallerij-behandelingen/behandelingen4.webp";
import behandelingen5 from "../assets/images/gallerij-behandelingen/behandelingen5.webp";
import behandelingen6 from "../assets/images/gallerij-behandelingen/behandelingen6.webp";
import behandelingen7 from "../assets/images/gallerij-behandelingen/behandelingen7.webp";
import behandelingen8 from "../assets/images/gallerij-behandelingen/behandelingen8.webp";
import behandelingen9 from "../assets/images/gallerij-behandelingen/behandelingen9.webp";
import behandelingen10 from "../assets/images/gallerij-behandelingen/behandelingen10.webp";
import behandelingen11 from "../assets/images/gallerij-behandelingen/behandelingen11.webp";
import behandelingen12 from "../assets/images/gallerij-behandelingen/behandelingen12.webp";
import behandelingen13 from "../assets/images/gallerij-behandelingen/behandelingen13.webp";
import behandelingen14 from "../assets/images/gallerij-behandelingen/behandelingen14.webp";
import behandelingen15 from "../assets/images/gallerij-behandelingen/behandelingen15.webp";
import behandelingen16 from "../assets/images/gallerij-behandelingen/behandelingen16.webp";
import behandelingen17 from "../assets/images/gallerij-behandelingen/behandelingen17.webp";


import { ImagesListType } from "react-spring-lightbox";
import SectionContainer from "../shared/components/section-container/section-container";

function GalleryPage() {
    const imagesInstitutuut: ImagesListType = [
        {
            src: instituut1,
            loading: "lazy",
            alt: "blablabla"
        },
        {
            src: instituut5,
            loading: "lazy",
            alt: "blablabla"
        },
        {
            src: instituut3,
            loading: "lazy",
            alt: "blablabla"
        },
        {
            src: instituut2,
            loading: "lazy",
            alt: "blablabla"
        },
        {
            src: instituut6,
            loading: "lazy",
            alt: "blablabla"
        },
        {
            src: instituut4,
            loading: "lazy",
            alt: "blablabla"
        }
    ];

    const imagesBehandelingen: ImagesListType = [
        {
            src: behandelingen1,
            loading: "lazy",
            alt: "blablabla"
        },
        {
            src: behandelingen12,
            loading: "lazy",
            alt: "blablabla"
        },
        {
            src: behandelingen15,
            loading: "lazy",
            alt: "blablabla"
        },
        {
            src: behandelingen4,
            loading: "lazy",
            alt: "blablabla"
        },
        {
            src: behandelingen9,
            loading: "lazy",
            alt: "blablabla"
        },
        {
            src: behandelingen13,
            loading: "lazy",
            alt: "blablabla"
        },
        {
            src: behandelingen3,
            loading: "lazy",
            alt: "blablabla"
        },
        {
            src: behandelingen17,
            loading: "lazy",
            alt: "blablabla"
        },
        {
            src: behandelingen6,
            loading: "lazy",
            alt: "blablabla"
        },
        {
            src: behandelingen10,
            loading: "lazy",
            alt: "blablabla"
        },
        {
            src: behandelingen7,
            loading: "lazy",
            alt: "blablabla"
        },
        {
            src: behandelingen2,
            loading: "lazy",
            alt: "blablabla"
        },
        {
            src: behandelingen14,
            loading: "lazy",
            alt: "blablabla"
        },
        {
            src: behandelingen8,
            loading: "lazy",
            alt: "blablabla"
        },
        {
            src: behandelingen11,
            loading: "lazy",
            alt: "blablabla"
        },
        {
            src: behandelingen16,
            loading: "lazy",
            alt: "blablabla"
        },

    ];
    return (
        <>
            <HeroSectionContainer>
                <div className="flex flex-col items-center justify-center w-full my-8
                md:my-16
                lg:my-24">
                    <h1 className="text-xxl font-bold text-center highlight">Het instituut</h1>
                </div>
                <ImageGallery images={imagesInstitutuut} />
            </HeroSectionContainer>
            <SectionContainer>
            <div className="flex flex-col items-center justify-center w-full my-8
                md:my-16
                lg:my-24">
                    <h1 className="text-xxl font-bold text-center mx-auto highlight">Behandelingen</h1>
                </div>
                <ImageGallery images={imagesBehandelingen} />
            </SectionContainer>
        </>
    );
}

export default GalleryPage;