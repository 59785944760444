import ScrollDownIcon from "../../../shared/components/scroll-down-icon/scroll-down-icon";

function BehandelingenHero() {
    return (
        <div className="flex flex-col items-center justify-center w-full text-center">
            <div className="bg-behandelingen bg-cover bg-center bg-no-repeat w-full h-screen brightness-50 absolute shadow-[0_0_50px_rgba(0,0,0,0.9)_inset]"></div>
            <div className="flex flex-col items-center justify-center w-full z-10 bg-white bg-opacity-5 backdrop-blur-sm mb-20
                            lg:w-1/2"
            >
                <div className="flex flex-row items-center justify-center gap-1 text-lapooLichtGroen text-lg uppercase font-bold">
                    <span className="hidden lg:block ml-4">-</span>
                    <p className="w-fit my-5">behandelingen</p>
                    <span className="hidden lg:block mr-4">-</span>
                </div>
                <h1 className="text-white text-xl md:text-xxl font-bold mb-8 mx-4
                    lg:mb-12
                    ">
                    Bekijk ons uitgebreid aanbod aan&nbsp;
                    <span className="highlight">behandelingen</span>
                    &nbsp;.
                </h1>
            </div>
            <div className="absolute bottom-0 text-white">
                <ScrollDownIcon />
            </div>
        </div>
    );
}

export default BehandelingenHero;