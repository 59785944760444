import FramerScrollDown from "../../../framer-effects/framer-scroll-down";
import BehandelingType from "../behandeling-type";

function BehandelingCard({ behandeling }: { behandeling: BehandelingType }) {
    return (
        <>
            <div className="flex flex-col items-center justify-start h-full mx-5">
                <div className="max-w-sm rounded overflow-hidden shadow-2xl bg-gray-100 h-fit w-full">
                    <img className="w-full" src={behandeling.src} alt={behandeling.alt} />
                    <div className="px-6 py-4">
                        <div className="flex flex-col w-full justify-between items-start">
                            <div className="font-bold text-md">{behandeling.name}</div>
                            <div className="font-bold text-lapooGroen text-md my-2 whitespace-nowrap">{behandeling.price}</div>
                        </div>
                        <p className="text-gray-700 text-sm">
                            <span className="font-semibold">Beschrijving:</span><br />{behandeling.text}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BehandelingCard;