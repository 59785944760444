import gelaatsverzorgingen from "./assets/images/categories/gelaatsverzorgingen.webp";
import voetverzorgingen from "./assets/images/categories/voetverzorgingen.webp";

import bloedsinaasappel from "./assets/images/gelaat/bloedsinaasappel.webp";
import purifiant from "./assets/images/gelaat/purifiant.webp";
import bamboe from "./assets/images/gelaat/bamboe.webp";
import spiruline from "./assets/images/gelaat/spiruline.webp";
import spirulineOog from "./assets/images/gelaat/spiruline_oog.webp";
import yuzu from "./assets/images/gelaat/yuzu.jpg";
import diamant from "./assets/images/gelaat/diamant.webp";
import basisGelaat from "./assets/images/gelaat/basis_gelaat.jpg";
import muesli from "./assets/images/gelaat/muesli.jpg";
import jardin from "./assets/images/gelaat/jardin.webp";
import soinSolideGelaat from "./assets/images/gelaat/soin-solide-gelaat.webp";

import figuurCat from "./assets/images/categories/figuur_cat.jpg";
import figuur1 from "./assets/images/figuur/figuur_1.jpg";
import figuur2 from "./assets/images/figuur/figuur_2.jpg";
import figuur3 from "./assets/images/figuur/figuur_3.jpg";

import soinsdumonde from "./assets/images/lichaam/soinsdumonde.jpg";
import peelingRug from "./assets/images/lichaam/peeling_rug.webp";
import relaxmassageRug from "./assets/images/lichaam/relaxmassage_rug.webp";
import peelingLichaam from "./assets/images/lichaam/peeling_lichaam.webp";
import relaxAroma from "./assets/images/lichaam/relax_aroma.webp";
import relaxKaarsen from "./assets/images/lichaam/relax_kaarsen.webp";
import relaxLichaam from "./assets/images/lichaam/relaxmassage_lichaam.webp";
import soinSolideLichaam from "./assets/images/lichaam/soin-solide-lichaam.webp";

import handverzorgingen from "./assets/images/categories/handverzorgingen.webp";
import gelnagels from "./assets/images/handen/gelnagels.jpg";
import gelish from "./assets/images/handen/gelish.webp";
import basisManicure from "./assets/images/handen/basis_manicure.webp";
import spaManicure from "./assets/images/handen/spa_manicure.webp";
import paraffine from "./assets/images/handen/paraffine.webp";
import handMassage from "./assets/images/handen/hand_massage.webp";

import lichaamCat from "./assets/images/categories/lichaam_cat.webp";
import spaPedicure from "./assets/images/voeten/spa_pedicure.webp";
import basisPedicure from "./assets/images/voeten/pedicure.webp";
import gelishVoeten from "./assets/images/voeten/gelish_voeten.webp";
import paraffineVoet from "./assets/images/voeten/paraffine_voet.webp";
import massageVoet from "./assets/images/voeten/massage_voet.webp";

import ontharingenCat from "./assets/images/categories/ontharingen_cat.webp";
import benenVolledig from "./assets/images/ontharingen/benen_volledig.webp";
import onderbenen from "./assets/images/ontharingen/onderbenen.webp";
import bikinilijn from "./assets/images/ontharingen/bikinilijn.webp";
import armen from "./assets/images/ontharingen/armen.webp";
import wenkbrauwen from "./assets/images/ontharingen/wenkbrauwen.webp";
import rug from "./assets/images/ontharingen/rug.webp";
import oksels from "./assets/images/ontharingen/oksels.webp";
import bovenlip from "./assets/images/ontharingen/bovenlip.webp";
import rugBorst from "./assets/images/ontharingen/rug_borst.webp";
import bovenlipKinWangen from "./assets/images/ontharingen/bovenlip_kin_wangen.webp";
import neus from "./assets/images/ontharingen/neus.webp";
import kin from "./assets/images/ontharingen/kin.webp";

import wimpers from "./assets/images/wimpers/wimpers.webp";
import wimpers2 from "./assets/images/wimpers/wimpers2.webp";
import wimpers3 from "./assets/images/wimpers/wimpers3.webp";
import wimpers4 from "./assets/images/wimpers/wimpers4.webp";
import wimpersCat from "./assets/images/categories/wimpers_cat.webp";
import lashlift from "./assets/images/wimpers/lashlift.webp";
import wenkVerven from "./assets/images/wimpers/wenk_verven.webp";
import wimpVerven from "./assets/images/wimpers/wimp_verven.webp";
import epilerenWenk from "./assets/images/wimpers/epileren_wenk.webp";
import wenkWimpVerven from "./assets/images/wimpers/wenkwimp_verven.webp";

import dagMakeup from "./assets/images/makeup/dag_makeup.webp";
import feestMakeup from "./assets/images/makeup/feest_makeup.webp";
import makeupCat from "./assets/images/categories/makeup_cat.webp";

export const behandelingen = [
    {
        category : "Gelaatsverzorgingen",
        src : gelaatsverzorgingen,
        values : [
            {
                name : "Basis gelaatsverzorging",
                src : basisGelaat,
                description : "Reiniging, dieptereiniging, indien gewenst epilatie wenkbrauwen en ontharen van de bovenlip, vapozone, zwarte puntjes, massage, masker en dagcrème.",
                price : 56
            },
            {
                name : "Anti-aging behandeling met spiruline",
                src : spiruline,
                description : "Deze behandeling gaat de huid verstevigen. Hij doet de rimpels zichtbaar verminderen en gaat de span en veerkracht van de huid verbeteren.",
                price : 74
            },
            {
                name : "Luxe anti-age verzorging",
                src : diamant,
                description : "Deze behandeling bestrijdt doeltreffend rimpels, kraaienpootjes, verlies van stevigheid en pigmentvlekken. Deze lijn werd verrijkt met diamantpoeder en extract van witte parels om de kwaliteit en de glans van uw teint te verbeteren. Deze behandeling wordt uitgevoerd met behulp van massageparels, een revolutionaire massagetool die zorgt voor een volledig nieuwe ervaring en maximale ontspanning.",
                price : 98
            },
            {
                name : "Hydraterende behandeling met bamboe",
                src : bamboe,
                description : "Behandeling voor de huid die nood heeft aan hydratatie, soepelheid en stevigheid. Hoofdbestanddeel van deze behandeling zijn bamboe en aloe vera.",
                price : 65
            },
            {
                name : "Kalmerende behandeling met muesli",
                src : muesli,
                description : "Behandeling die de gevoelige huid gaat kalmeren en de droge huid gaat hydrateren. Het verminderd zichtbaar de roodheden, droogheid en ruwe uitzicht van de huid. Muesli gaat de huid versterken en helpt het evenwicht van het huidmicrobioom te behouden.",
                price : 61
            },
            {
                name : "Behandeling voor de oogcontouren met spiruline",
                src : spirulineOog,
                description : "Een doeltreffende verzorging om tekenen van vermoeidheid en veroudering tegen te gaan. Deze verzorging gaat rimpels zichtbaar verzachten en wallen verminderen. Boek je deze bovenop een gelaatsverzorging? Dan kan dit voor slechts 18€.",
                price : 28
            },
            {
                name : "Soin purifiant",
                src : purifiant, 
                description : "Een zuiverende behandeling voor oneffenheden en de overmatige productie van talg. Hormonale schommelingen, warmte en vocht, vervuiling, onregelmatige levensstijl of zelfs stress kunnen overproductie van talg of huidonzuiverheden veroorzaken.",
                price : 61
            },
            {
                name : "Yuzu behandeling",
                src: yuzu,
                description : "Deze behandeling zorgt voor een lichtere, meer stralende teint en gaat pigmentvlekken vervagen. Bovendien zal de pigmentvorming ook worden afgeremd.",
                price : 67
            },
            
            {
                name : "EE* detox behandeling met bloedsinaasappel",
                src : bloedsinaasappel,
                description : "Deze behandeling zorgt dat de huid er minder vermoeid en terug fris uitziet, geeft een egale en heldere teint. Vitamine C is een van de hoofdbestanddelen van deze behandeling.",
                price : 61
            },

            {
                name: "Jardin aromatique",
                src: jardin,
                description: "Een reinigende en zuiverende behandeling voor een gladdere, zuiverdere huid met een frisse teint. Deze behandeling is zeer effectief, met name dankzij een dubbele grondige reiniging en een absorberend masker. Het is een ideale behandeling voor de huid die gevoelig is voor onzuiverheden, overtollig talg of verwijde poriën. Het is een essentiële behandeling om het uiterlijk van de huid te verbeteren en een gelijkmatige teint te herstellen.",
                price: 59
            },
            
            {
                name: "Soin solide gelaat",
                src: soinSolideGelaat,
                description: "100% biologisch en plantaardige behandeling zonder toegevoegde parfums. Deze behandeling werkt kalmerend en zuiverend. Wat maakt deze behandeling zo speciaal naast een standaard gelaatsverzorging? De huid reinigen we met zeep (deze is speciaal voor het gelaat). Na de reiniging gebeurd de peeling maar dit door middel van zijdecoconnetjes. Nadien brengen we het masker aan. Pas na het masker volgt de massage. Bij deze behandeling krijgt de klant de gebruikte blokjes zeep & het blokje van de massage olie mee naar huid (twv €30).",
                price: 72
            }
        ]
    },
    {
        category: "Figuurcorrectie",
        src : figuurCat,
        values : [
            {
                name : "Ananas afslankbehandeling",
                src : figuur1,
                description : "Deze behandeling kan toegepast worden op volgende lichaamsdelen; dijen, heupen, billen, armen en buik. De behandeling gaat het uiterlijk van de huid ter hoogte van de behandelde zones verbeteren, verstevigen en het silhouet verfijnen.",
                price : 80
            },
            {
                name : "Ananas afslankbehandeling kuur (4 behandelingen)",
                src : figuur2,
                description : "Deze behandeling kan toegepast worden op volgende lichaamsdelen; dijen, heupen, billen, armen en buik. De behandeling gaat het uiterlijk van de huid ter hoogte van de behandelde zones verbeteren, verstevigen en het silhouet verfijnen.",
                price : 300
            },
            {
                name : "Ananas afslankbehandeling kuur (8 behandelingen)",
                src : figuur3,
                description : "Deze behandeling kan toegepast worden op volgende lichaamsdelen; dijen, heupen, billen, armen en buik. De behandeling gaat het uiterlijk van de huid ter hoogte van de behandelde zones verbeteren, verstevigen en het silhouet verfijnen.",
                price : 580
            },
        ]
    },
    {
        category : "Handverzorgingen",
        src : handverzorgingen,
        values : [
            {
                name : "Basis manicure",
                src : basisManicure,
                description : "Vijlen van de nagels, losmaken van de velletjes, achteruit duwen van de nagelriem,  verwijderen van de dode huid aan de nagelriem en afwerking met een voedende nagel olie. De nagels kunnen ook gelakt worden bij een manicure.",
                price : 25
            },
            {
                name : "Gelnagels",
                src : gelnagels,
                description : "Het gaat hier om kunstnagels met een speciale gel die je uithardt onder een UV-lamp of LED-lamp. Je kunt de gel laten aanbrengen op je natuurlijke nagels om ze te verstevigen of te verlengen. Voor een mooi resultaat wordt bij gelnagels eerst een basislaagje aangebracht, daarna volgt een gellaag, meestal twee kleurlaagjes en een eindlaag. De gelnagels blijven 3 tot 4 weken mooi zitten. Daarna kun je de gel laten bijwerken of verwijderen.",
                price : 48
            },
            {
                name : "Gelish & manicure",
                src : gelish,
                description : "Gelish is flexibel, duurzaam en eenvoudig te verwijderen. Met gelish hoeft je niet langer te wachten tot de nagels droog zijn na de behandeling. Afbrokkelen, krasjes en dofheid, zwakke en beschadigde nagels die snel inscheuren, … behoren definitief tot het verleden. Gelish nagels bieden u kwaliteit en comfort! Dit voor 3 tot 4 weken!",
                price : 37
            },
            {
                name : "Spa manicure",
                src : spaManicure,
                description : "Bij deze behandeling krijgt je eerst een handbadje waarbij de handen gepeeld worden. Dit om de dode huidcellen los te maken en te verwijderen. Nadien volgt de gewone manicure en een handmasker. Deze behandeling wordt afgewerkt met een zalige handmassage.",
                price : 39
            },
            // {
            //     name : "Paraffinebehandeling voor de handen",
            //     src : paraffine,
            //     description : "Paraffine is één van de meest effectieve behandelingen om de huid van de handen optimaal te voeden en te beschermen. Het thermische effect van paraffine zorgt ervoor dat de bloedcirculatie wordt geactiveerd zodat de huid voedingstoffen optimaal kan opnemen. De natuurlijke beschermlaag wordt versterkt en de eerste tekenen van het ouder worden, worden vertraagd.",
            //     price : 25
            // },
            {
                name : "Handmassage",
                src : handMassage,
                description : "Een handmassage zorgt niet alleen voor ontspanning maar ook voor een betere doorbloeding van de huid. Het maakt de handspieren soepeler en gaat de handen volledig ontspannen.",
                price : 9
            },
            
            
        ]
    },
    {
        category : "Voetverzorgingen",
        src : voetverzorgingen,
        values : [
            {
                name : "Basis pedicure",
                src : basisPedicure,
                description : "Knippen & vijlen van de nagels, losmaken van de velletjes ter hoogte van de nagelriem. Het verwijderen van eelt, likdoorns, ingegroeide nagels,… worden standaard toegepast bij onze pedicures. U wandelt het instituut buiten alsof u op wolkjes loopt.",
                price : 28
            },
            
            {
                name : "Gelish & pedicure",
                src : gelishVoeten,
                description : "Gelish is flexibel, duurzaam en eenvoudig te verwijderen. Met gelish hoeft je niet langer te wachten tot de nagels droog zijn na de behandeling. Afbrokkelen, krasjes en dofheid, zwakke en beschadigde nagels die snel inscheuren, … behoren definitief tot het verleden. Gelish nagels bieden u kwaliteit en comfort! Dit voor 6 tot 8 weken!",
                price : 39
            },
            {
                name : "Spa pedicure",
                src : spaPedicure,
                description : "Bij deze behandeling krijg je eerst een voetbadje waarbij de voeten gepeeld worden. Dit om de dode huidcellen los te maken en te verwijderen. Nadien volgt een pedicure en een voetmasker. Deze behandeling wordt afgewerkt met een zalige voetmassage.",
                price : 48
            },
            // {
            //     name : "Paraffinebehandeling voor de voeten",
            //     src : paraffineVoet,
            //     description : "Paraffine is één van de meest effectieve behandelingen om de huid van de voeten optimaal te voeden en te beschermen. Het thermische effect van paraffine zorgt ervoor dat de bloedcirculatie wordt geactiveerd zodat de huid voedingstoffen optimaal kan opnemen. De natuurlijke beschermlaag wordt versterkt en de eerste tekenen van het ouder worden, worden vertraagd.",
            //     price : 26
            // },
            {
                name : "Voetmassage",
                src : massageVoet,
                description : "Een voetmassage geeft ontspanning voor je voeten, maar ook je hele lichaam. De voetmassage heeft als doel het bewerken van de voet spieren en het stimuleren van de bloedsomloop in de voet. De lymfen worden getraind om vocht af te voeren. Tijdens een voetmassage zullen diverse voetspieren droog gemaakt worden, om vervolgens met toenemende druk de spieren te stimuleren in hun vermogen. Een voetmassage kan verzachting bieden bij klachten en verschijnselen als spanning, hoofdpijn, slapeloosheid en koud, verkrampte en gespannen voeten.",
                price : 9
            },
            
        ]
    },
    {
        category : "Lichaamsverzorgingen",
        src : lichaamCat,
        values : [
            {
                name : "Relaxmassage volledig lichaam",
                src : relaxLichaam,
                description : "Een massage is niet alleen heerlijk ontspannend, het activeert ook de bloedsomloop. Hierdoor krijgen de spieren, organen, botten en huid meer verse voedingsstoffen. De energiedoorstroming wordt bevorderd, waardoor het lichaam nieuwe energie krijgt! Bovendien wordt de bloeddruk genormaliseerd door massage.",
                price : 61
            },
            {
                name : "Relaxmassage rug",
                src : relaxmassageRug,
                description : "Een massage is niet alleen heerlijk ontspannend, het activeert ook de bloedsomloop. Hierdoor krijgen de spieren, organen, botten en huid meer verse voedingsstoffen. De energiedoorstroming wordt bevorderd, waardoor het lichaam nieuwe energie krijgt! Bovendien wordt de bloeddruk genormaliseerd door massage.",
                price : 35
            },
            {
                name : "Peeling lichaam",
                src : peelingLichaam,
                description : "Behandeling om de dode huidcellen te verwijderen en nadien het lichaam te hydrateren. Ideaal voor het zonnebaden.",
                price : 71
            },
            {
                name : "Peeling rug",
                src : peelingRug,
                description : "Behandeling om de dode huidcellen te verwijderen en de rug nadien te hydrateren. Eventuele zwarte punten worden ook verwijderd.",
                price : 41
            },
            {
                name : "Wereldbehandelingen: Soins du monde",
                src : soinsdumonde,
                description : "Scrub, pakking en massage. Een ware zintuiglijke ervaring, om terug te keren naar de oorsprong van de wereld, weer in harmonie te komen met de natuur en meegevoerd te worden naar verre landen. Deze behandeling wordt aanbevolen voor iedereen die zich wil ontspannen en even tot rust wil komen.",
                price : 85
            },
            // {
            //     name : "Relaxmassage met aromatherapie",
            //     src : relaxAroma,
            //     description : "Massage met aangepaste etherische olieën. Deze worden gekozen naargelang de noden van de klant. Bijvoorbeeld stimulerend, relaxerend of verkwikkend.",
            //     price : 55
            // },
            {
                name : "Relaxmassage met kaarsen",
                src : relaxKaarsen,
                description : "Een lichaamsmassage die u dankzij geurende warme olie meeneemt in een wereld van ontspanning.",
                price : 68
            },
            {
                name: "Soin solide lichaam",
                src: soinSolideLichaam,
                description: "100% biologische en plantaardige behandeling zonder toegevoegde parfums. Ontspannende lichaamsverzorging waarbij we een uniek ritueel hanteren. Deze behandeling is zero waste. Na het reinigen van de huid met een zeep, masseren we het lichaam met een blokje (solide) die zich omzet tot olie. Zowel het stukje zeep als het blokje voor de massage worden nadien met de klant meegegeven naar huis (twv €35). Zo kan u de verzorging thuis verder zetten.",
                price: 80
            }
        ]
    },
    {
        category : "Ontharingen",
        src : ontharingenCat,
        values : [
            {
                name : "Bovenlip of kin",
                src : bovenlip,
                description : "",
                price : 12
            },
            {
                name : "Bovenlip en kin",
                src : kin,
                description : "",
                price : 13
            },
            {
                name : "Bovenlip, kin & wangen",
                src : bovenlipKinWangen,
                description : "",
                price : 14
            },
            {
                name : "Benen tot aan de knie",
                src : onderbenen,
                description : "",
                price : 25
            },
            {
                name : "Benen volledig",
                src : benenVolledig,
                description : "",
                price : 35
            },
            {
                name : "Oksels",
                src : oksels,
                description : "",
                price : 13
            },
            {
                name : "Bikinilijn (Brazilian)",
                src : bikinilijn,
                description : "",
                price : 22
            },
            {
                name : "Rug of borst",
                src : rug,
                description : "",
                price : 32
            },
            {
                name : "Rug & borst",
                src : rugBorst,
                description : "",
                price : 52
            },
            {
                name : "Neus & oren",
                src : neus,
                description : "",
                price : 14
            },
            {
                name : "Armen",
                src : armen,
                description : "",
                price : 24
            },
            {
                name : "Wenkbrauwen",
                src : wenkbrauwen,
                description : "",
                price : 12
            },
        ]
    },
    {
        category : "Wimper- en wenkbrauwverzorgingen",
        src : wimpersCat,
        values : [
            {
                name : "Wimperextensions (nieuwe set)",
                src : wimpers,
                description : "Wimperextensions werken eigenlijk net als gewone haar extensions. Er worden kleine haartjes op je eigen wimpers geplakt met een speciale (formaldehyde vrije) lijm. Dit zijn meestal synthetische haartjes, die kunnen variëren in kleur, dikte en lengte.",
                price : 125
            },
            {
                name : "Wimperextensions (mini bijwerking)",
                src : wimpers2,
                description : "Wil je je wimpers zo houden en het effect niet beperken  dan moet je ze regelmatig laten bijwerken. Hoe vaak hangt af van de cyclus van je eigen wimpers maar gemiddeld gebeurt dit om de 2 à 3 weken. Want je wimpers en extensions vallen op natuurlijke wijze uit. Na ongeveer 8 weken zijn ze allemaal uitgevallen en zijn er nieuwe in de plaats gegroeid.",
                price : 32
            },
            {
                name : "Wimperextensions (bijwerking na 3 weken)",
                src : wimpers3,
                description : "Wil je je wimpers zo houden en het effect niet beperken  dan moet je ze regelmatig laten bijwerken. Hoe vaak hangt af van de cyclus van je eigen wimpers maar gemiddeld gebeurt dit om de 2 à 3 weken. Want je wimpers en extensions vallen op natuurlijke wijze uit. Na ongeveer 8 weken zijn ze allemaal uitgevallen en zijn er nieuwe in de plaats gegroeid.",
                price : 39
            },
            {
                name : "Wimperextensions (bijwerking na 4 weken)",
                src : wimpers4,
                description : "Wil je je wimpers zo houden en het effect niet beperken  dan moet je ze regelmatig laten bijwerken. Hoe vaak hangt af van de cyclus van je eigen wimpers maar gemiddeld gebeurt dit om de 2 à 3 weken. Want je wimpers en extensions vallen op natuurlijke wijze uit. Na ongeveer 8 weken zijn ze allemaal uitgevallen en zijn er nieuwe in de plaats gegroeid.",
                price : 57
            },
            {
                name: "Lashlift met kleuren",
                src: lashlift,
                description: "Wimper Permanent! Voor sprekende ogen… Mooie, lange en gekrulde wimpers zorgen voor een open blik die de ogen optisch aanzienlijk groter laten lijken, zelfs bij korte wimpers. Dit resultaat blijft 6 tot 8 weken.",
                price: 55
            },
            {
                name: "Kleuren wimpers",
                src: wimpVerven,
                description: "De wimperverf voorziet de volledige wimperhaar van een gekleurd laagje, waardoor de gehele lengte van de wimper goed zichtbaar is. Hierdoor krijg je direct langer en voller uitziende wimpers.",
                price: 16
            },
            {
                name: "Kleuren wenkbrauwen",
                src: wenkVerven,
                description: "De verf legt een laagje om de wenkbrauwen, waardoor de wenkbrauwen meteen voller lijken. Vooral de uiteinden van de wenkbrauwen zijn vaak dunner, waardoor je ze bijna niet ziet. Met wenkbrauwverf zorg je ervoor dat je wenkbrauwen goed naar voren komen.",
                price: 16
            },
            {
                name: "Kleuren wimpers en wenkbrauwen",
                src: wenkWimpVerven,
                description: "",
                price: 28
            },
            {
                name: "Epileren wenkbrauwen",
                src: epilerenWenk,
                description: "",
                price: 12
            },
        ]
    },
    {
        category: "Make-up",
        src: makeupCat,
        values: [
            {
                name: "Dag make-up",
                src: dagMakeup,
                description: "Een dag make-up is bij voorkeur zo natuurlijk mogelijk en maakt de huid egaler en aantrekkelijker, met lichte accenten van kleur passend bij uw kleding of persoonlijkheid. Met lichte kleuren en toetsen die goed bij uw huidtype, kleding en persoonlijkheid passen, creëren we een frisse, natuurlijk ogende look.",
                price: 15
            },
            {
                name: "Feest make-up",
                src: feestMakeup,
                description: "Een schitterende feest make-up met feestelijke glitter-oogschaduw en sensuele glossy lippen. Stylish en trendy. Voor de feestdagen of iedere andere leuke en uitbundige feestelijke gelegenheid. Met donkere of gekleurde smokey eyes als uitgangspunt is deze feestlook heel gemakkelijk te maken. Alles draait om de ogen die met of zonder glitter zijn opgemaakt. Met deze make-up straal je de hele avond.",
                price: 43
            },
        ]
    }
];
