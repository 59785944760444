import FramerScrollDown from "../../framer-effects/framer-scroll-down";

function HomeIntro() {
    return (
        <div className="flex flex-col items-center justify-center w-full px-8 mt-20 xl:my-auto gap-y-8">
            <FramerScrollDown className="flex flex-col items-center justify-center text-center w-full gap-2
                            md:w-2/3">
                <h2 className="text-xl text-center font-semibold">
                    Schoonheidsinstituut <span className="highlight">Lapoo</span>
                </h2>
                <p className="text-md">
                    Een plaats, die helemaal gewijd is aan schoonheid en een gevoel van welbevinden.
                    Een atmosfeer, die vriendelijk en vertrouwd is.
                    Geniet van zachte aanrakingen, ervaar de weldaad van verzorging en tank nieuwe energie.
                </p>

                <p className="text-md">
                    Wij bieden een uniek concept dat creativiteit, originaliteit, moderniteit en plezier combineert
                    met professionalisme en raadgevingen, waardoor u altijd verzekerd bent van de beste resultaten.
                </p>
            </FramerScrollDown>
            <FramerScrollDown className="grid grid-cols-1 items-center justify-center justify-items-center gap-x-2 gap-y-8 m-4
                            lg:grid-cols-3">
                <div className="flex flex-col items-center justify-start w-full lg:w-2/3 gap-2 h-full p-4
                                ">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 shadow-md text-lapooGroen bg-lapooLichtGroen/60 rounded-full p-2" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6"></path>
                        <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4"></path>
                    </svg>
                    <div className="flex flex-col items-center justify-center gap-4 w-full text-center">
                        <h3 className="text-md font-semibold highlight">Innovatie</h3>
                        <p className="text-base">
                            Door constante innovatie en verbetering van onze technieken zorgen
                            wij ervoor dat u steeds het beste resultaat kan verwachten.
                        </p>
                    </div>
                </div>

                <div className="flex flex-col items-center justify-start w-full lg:w-2/3 gap-2 h-full p-4
                                ">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 shadow-md text-lapooGroen bg-lapooLichtGroen/60 rounded-full p-2" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <polyline points="9 11 12 14 20 6"></polyline>
                        <path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9"></path>
                    </svg>
                    <div className="flex flex-col items-center justify-center gap-4 w-full text-center">
                        <h3 className="text-md font-semibold highlight">Kwaliteit</h3>
                        <p className="text-base">
                            Wij werken enkel met professionele en hoogwaardige
                            producten van top-kwaliteit. Door ons uitgebreid aanbod
                            aan producten krijgt u steeds een behandeling
                            die op maat gemaakt is naargelang uw huidtype.
                        </p>
                    </div>
                </div>

                <div className="flex flex-col items-center justify-start w-full lg:w-2/3 gap-2 h-full p-4
                                ">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 shadow-md text-lapooGroen bg-lapooLichtGroen/60 rounded-full p-2" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <rect x="4" y="5" width="16" height="16" rx="2"></rect>
                        <line x1="16" y1="3" x2="16" y2="7"></line>
                        <line x1="8" y1="3" x2="8" y2="7"></line>
                        <line x1="4" y1="11" x2="20" y2="11"></line>
                        <rect x="8" y="15" width="2" height="2"></rect>
                    </svg>
                    <div className="flex flex-col items-center justify-center gap-4 w-full text-center">
                        <h3 className="text-md font-semibold highlight">Afspraak</h3>
                        <p className="text-base">
                            Wij werken enkel op afspraak. Hierdoor blijft de wachttijd
                            minimaal en kunnen we uitgebreid de tijd nemen voor
                            uw verwenmoment.
                        </p>
                    </div>
                </div>
            </FramerScrollDown>
        </div>
    );
}

export default HomeIntro;