import { motion } from "framer-motion";
function FramerScrollDown({ children, className, delay, onClick }: { children?: any, className?: string, delay?: number, onClick?: any } = {}) {
    return (
        <motion.div
            className={className}
            onClick={onClick}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{
                duration: 1.5,
                delay: delay ? delay : 0.2,
                ease: [0, 0.71, 0.2, 1.01]
            }}
            variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 150 }
            }}
        >
            {children}
        </motion.div>
    );
}

export default FramerScrollDown;