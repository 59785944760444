import { NavLink } from "react-router-dom";
import FramerNavbarItem from "../../framer-effects/framer-navbar-item";
import Logo from "../../assets/icons/logo.jpg";
import { useState } from "react";

function Navbar() {
    const [mobileMenu, setMobileMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenu(!mobileMenu);
    }

    return (
        <div className="fixed flex flex-col justify-center items-center w-full
                        h-20 z-50 top-0 
                        border-b border-gray-100 bg-white">
            <header className="flex flex-row items-center justify-between w-full max-w-[112.5rem] px-[2rem]">

                {/* LOGO HOME */}
                <div className="w-1/4 flex flex-row items-center justify-start">
                    <NavLink to="/" aria-label="Logo van Schoonheidsinstituut Lapoo" className="flex flex-row w-fit gap-2 justify-start items-center ">
                        <img className="w-24" src={Logo} alt="Logo van Schoonheidsinstituut Lapoo" />
                        <span className="text-md font-bold">Lapoo</span>
                    </NavLink>
                </div>

                <div className="w-2/4 flex flex-row items-center justify-center whitespace-nowrap">

                    {/* NAVBAR */}
                    <nav className="hidden lg:flex flex-row items-center justify-center text-base lowercase">
                        <ul className="flex flex-row justify-end items-center gap-10">
                            <NavLink to="/" end
                                children={({ isActive }) => (
                                    <FramerNavbarItem isActive={isActive}>
                                        Home
                                    </FramerNavbarItem>
                                )} />

                            <NavLink to="/over-ons" end
                                children={({ isActive }) => (
                                    <FramerNavbarItem isActive={isActive}>
                                        Over Ons
                                    </FramerNavbarItem>
                                )} />

                            <NavLink to="/behandelingen"
                                children={({ isActive }) => (
                                    <FramerNavbarItem isActive={isActive}>
                                        Behandelingen
                                    </FramerNavbarItem>
                                )} />

                            <NavLink to="/gallerij" end
                                children={({ isActive }) => (
                                    <FramerNavbarItem isActive={isActive}>
                                        Gallerij
                                    </FramerNavbarItem>
                                )} />

                            <NavLink to="/cadeaubon" end
                                children={({ isActive }) => (
                                    <FramerNavbarItem isActive={isActive}>
                                        Cadeaubon
                                    </FramerNavbarItem>
                                )} />
                        </ul>
                    </nav>
                </div>

                {/* CONTACT */}
                <div className="hidden lg:flex w-1/4 flex-row items-center justify-end">
                    <NavLink
                        to="/contact"
                        end
                        className="px-5 py-2.5 relative rounded group text-white inline-block hover:-translate-y-1 transition-all duration-200">
                        <span className="absolute top-0 left-0 w-full h-full rounded opacity-50 filter blur-sm bg-gradient-to-br from-lapooLichtGroen to-lapooLichtGroen"></span>
                        <span className="h-full w-full inset-0 absolute mt-0.5 ml-0.5 bg-gradient-to-br filter group-active:opacity-0 rounded opacity-50 from-lapooLichtGroen to-lapooLichtGroen"></span>
                        <span className="absolute inset-0 w-full h-full transition-all duration-200 ease-out rounded shadow-xl bg-gradient-to-br filter group-active:opacity-0 group-hover:blur-sm from-lapooLichtGroen to-lapooLichtGroen"></span>
                        <span className="absolute inset-0 w-full h-full transition duration-200 ease-out rounded bg-gradient-to-br to-lapooLichtGroen from-lapooLichtGroen"></span>
                        <span className="relative flex flex-row gap-2">
                            contact
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-right" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                                <line x1="15" y1="16" x2="19" y2="12"></line>
                                <line x1="15" y1="8" x2="19" y2="12"></line>
                            </svg>
                        </span>
                    </NavLink>
                </div>

                {/* MOBILE NAV BUTTON */}
                <div className="lg:hidden flex flex-row items-center">
                    <button onClick={() => toggleMobileMenu()}>
                        {!mobileMenu && <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <line x1="4" y1="6" x2="20" y2="6"></line>
                            <line x1="4" y1="12" x2="20" y2="12"></line>
                            <line x1="4" y1="18" x2="20" y2="18"></line>
                        </svg>}

                        {mobileMenu && <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>}
                    </button>
                </div>
            </header>
            {mobileMenu &&
                <div onClick={() => toggleMobileMenu()} className="fixed z-50 top-20 flex flex-col items-center justify-center bg-white w-full h-screen">
                    <nav className="flex flex-col items-center justify-center text-base lowercase bg-white">
                        <ul className="flex flex-col justify-end items-center gap-10">
                            <NavLink to="/" end
                                children={({ isActive }) => (
                                    <FramerNavbarItem isActive={isActive}>
                                        Home
                                    </FramerNavbarItem>
                                )} />

                            <NavLink to="/over-ons" end
                                children={({ isActive }) => (
                                    <FramerNavbarItem isActive={isActive}>
                                        Over Ons
                                    </FramerNavbarItem>
                                )} />

                            <NavLink to="/behandelingen"
                                children={({ isActive }) => (
                                    <FramerNavbarItem isActive={isActive}>
                                        Behandelingen
                                    </FramerNavbarItem>
                                )} />

                            <NavLink to="/gallerij" end
                                children={({ isActive }) => (
                                    <FramerNavbarItem isActive={isActive}>
                                        Gallerij
                                    </FramerNavbarItem>
                                )} />

                            <NavLink to="/cadeaubon" end
                                children={({ isActive }) => (
                                    <FramerNavbarItem isActive={isActive}>
                                        Cadeaubon
                                    </FramerNavbarItem>
                                )} />

                            <NavLink to="/contact" end
                                children={({ isActive }) => (
                                    <FramerNavbarItem isActive={isActive}>
                                        Contact
                                    </FramerNavbarItem>
                                )} />
                        </ul>
                    </nav>
                </div>
            }
        </div>
    );
}

export default Navbar;