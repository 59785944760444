import SectionContainer from "../shared/components/section-container/section-container";
import HeroSectionContainer from "../shared/components/section-container/hero-section-container";
import HomeHero from "../components/home/home-hero";
import HomeIntro from "../components/home/home-intro";
import HomeNews from "../components/home/home-news";
import HomeReviews from "../components/home/home-reviews";

function Homepage() {
    return (
        <div className="flex flex-col items-center w-full justify-center gap-16">
            <HeroSectionContainer>
                <HomeHero />
            </HeroSectionContainer>

            <SectionContainer>
                <HomeIntro />
                <HomeNews />
            </SectionContainer>

            <SectionContainer>
                <HomeReviews />
            </SectionContainer>
        </div>
    );
}

export default Homepage;