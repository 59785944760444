import { useRef, useState } from "react";
import SectionContainer from "../../shared/components/section-container/section-container";
import FramerScrollDown from "../../framer-effects/framer-scroll-down";
import { behandelingen } from "../../behandelingen";
import BehandelingCard from "./behandeling-card/behandeling-card";
import BehandelingType from "./behandeling-type";

function BehandelingenOverzicht() {
    const [cardsInfo, setCardsInfo] = useState<any>(null);
    const [currentBehandelingen, setCurrentBehandelingen] = useState<any>(null);

    const [gender, setGender] = useState("");
    const behandelingenRef = useRef<HTMLDivElement>(null);

    const handleCategoryClick = (name: string) => {
        const i = currentBehandelingen.findIndex((e: any) => e.category === name);
        if (i > -1) {
            setCardsInfo(currentBehandelingen[i]);
            behandelingenRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
    }

    const handleGenderSelect = (gender: string) => {
        setGender(gender);

        if (gender === "female") {
            setCurrentBehandelingen(behandelingen);
        } else {
            let helper: any[] = [];

            behandelingen.forEach(cat => {
                let values: any[] = [];

                cat.values.forEach(val => {
                    if (val.name !== "Bikinilijn (Brazilian)") values.push(val);
                })

                helper.push({
                    category: cat.category,
                    src: cat.src,
                    values: values
                });
            })

            setCurrentBehandelingen(helper);
        }
        setCardsInfo(null);
    }

    return (
        <>
            <SectionContainer>
                <div className="self-start flex flex-col items-center justify-start w-full ">
                    <div className="flex flex-col items-center justify-start w-full text-center my-28
                                    md:w-1/2">
                        <FramerScrollDown>
                            <h1 className="text-xl font-bold mx-8 my-4">
                                Verzorgingen op maat met oog voor detail
                            </h1>
                        </FramerScrollDown>
                        <FramerScrollDown>
                            <p className="text-lg mx-8">
                                Bij instituut Lapoo bieden we een groot assortiment aan behandelingen aan.
                                Manicures, wimperextensions, massages, ... Voor ieder wat wils.
                            </p>
                        </FramerScrollDown>
                    </div>
                    <div className="w-full grid grid-cols-1 items-center justify-center gap-y-4 my-10 text-md
                                    md:w-1/2 md:grid-cols-3">
                        <FramerScrollDown onClick={() => handleGenderSelect("male")} className={gender === "male" ? "col-span-1 w-full flex flex-col items-center justify-center" : "col-span-1 w-full flex flex-col items-center justify-center group hover:cursor-pointer active:scale-[.98]"}>
                            <svg xmlns="http://www.w3.org/2000/svg" className={gender === "male" ? "w-24 h-24  -translate-y-1 transition-all duration-200" : "w-24 h-24  group-hover:-translate-y-1 transition-all duration-200"} width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <circle cx="10" cy="14" r="5"></circle>
                                <path d="M19 5l-5.4 5.4"></path>
                                <path d="M19 5h-5"></path>
                                <path d="M19 5v5"></path>
                            </svg>
                            <h2 className={gender === "male" ? "font-semibold text-center text-3xl  -translate-y-1 transition-all duration-200 underline underline-offset-8 decoration-lapooLichtGroen/70 decoration-4" : "font-semibold text-center text-3xl  group-hover:-translate-y-1 transition-all duration-200 group-hover:underline underline-offset-8 decoration-lapooLichtGroen/70 decoration-4"}>Man</h2>
                        </FramerScrollDown>
                        <FramerScrollDown onClick={() => handleGenderSelect("female")} className={gender === "female" ? "col-span-1 w-full flex flex-col items-center justify-center" : "col-span-1 w-full flex flex-col items-center justify-center group hover:cursor-pointer active:scale-[.98]"}>
                            <svg xmlns="http://www.w3.org/2000/svg" className={gender === "female" ? "w-24 h-24  -translate-y-1 transition-all duration-200" : "w-24 h-24  group-hover:-translate-y-1 transition-all duration-200"} width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <circle cx="12" cy="9" r="5"></circle>
                                <path d="M12 14v7"></path>
                                <path d="M9 18h6"></path>
                            </svg>
                            <h2 className={gender === "female" ? "font-semibold text-center text-3xl  -translate-y-1 transition-all duration-200 underline underline-offset-8 decoration-lapooLichtGroen/70 decoration-4" : "font-semibold text-center text-3xl  group-hover:-translate-y-1 transition-all duration-200 group-hover:underline underline-offset-8 decoration-lapooLichtGroen/70 decoration-4"}>Vrouw</h2>
                        </FramerScrollDown>
                        <FramerScrollDown onClick={() => handleGenderSelect("other")} className={gender === "other" ? "col-span-1 w-full flex flex-col items-center justify-center" : "col-span-1 w-full flex flex-col items-center justify-center group hover:cursor-pointer active:scale-[.98]"}>
                            <svg xmlns="http://www.w3.org/2000/svg" className={gender === "other" ? "w-24 h-24  -translate-y-1 transition-all duration-200" : "w-24 h-24  group-hover:-translate-y-1 transition-all duration-200"} width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <circle cx="11" cy="11" r="4"></circle>
                                <path d="M19 3l-5 5"></path>
                                <path d="M15 3h4v4"></path>
                                <path d="M11 16v6"></path>
                                <path d="M8 19h6"></path>
                            </svg>
                            <h2 className={gender === "other" ? "font-semibold text-center text-3xl  -translate-y-1 transition-all duration-200 underline underline-offset-8 decoration-lapooLichtGroen/70 decoration-4" : "font-semibold text-center text-3xl  group-hover:-translate-y-1 transition-all duration-200 group-hover:underline underline-offset-8 decoration-lapooLichtGroen/70 decoration-4"}>Anders</h2>
                        </FramerScrollDown>
                    </div>
                    <div className="flex flex-row flex-wrap w-full items-center justify-center gap-20 my-10">
                        {currentBehandelingen && currentBehandelingen.map((b: any, index: any) => {
                            return <FramerScrollDown
                                onClick={() => handleCategoryClick(b.category)}
                                className="flex flex-col items-center justify-center max-w-sm bg-white group transition-all
                                                    hover:cursor-pointer"
                                key={index}
                                delay={index * 0.15}>
                                <img className={cardsInfo?.category === b.category ?
                                    "max-w-xs rounded-lg p-1 bg-lapooLichtGroen shadow-lg" :
                                    "max-w-xs rounded-lg p-1 group-hover:bg-lapooLichtGroen/70 transition-all duration-200 group-hover:shadow-lg"} src={b.src} alt="" />
                                <h3 className={cardsInfo?.category === b.category ?
                                    "text-md mt-1 text-center italic underline underline-offset-8 decoration-lapooLichtGroen decoration-4" :
                                    "text-md mt-1 text-center italic group-hover:underline underline-offset-8 decoration-lapooLichtGroen/70 decoration-4"}>{b.category}</h3>
                            </FramerScrollDown>
                        })}
                    </div>
                </div>
            </SectionContainer>
            {cardsInfo && gender && <SectionContainer>
                <div ref={behandelingenRef} className="grid grid-flow-row-dense grid-cols-1 w-full md:w-3/4 items-center justify-center gap-8 mb-10
                md:grid-cols-2 xl:grid-cols-3">
                    {cardsInfo.values.map((value: any) => {
                        return <BehandelingCard behandeling={{
                            src: value.src ? value.src : "",
                            alt: value.name,
                            name: value.name,
                            price: "€" + value.price,
                            text: value.description,
                        } as BehandelingType} />
                    })}
                </div>
                {/* <div className="flex flex-col items-center justify-center text-start bg-lapooLichtGroen fixed bottom-0 right-0 mr-4 w-28">
                    <span>Boek nu je afspraak!</span>
                </div> */}
            </SectionContainer>}
        </>
    );
}
export default BehandelingenOverzicht;