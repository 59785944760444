import Anja from "../../assets/images/overons/anja_resized.webp";
import Janice from "../../assets/images/overons/janice_resized.webp";
import FramerScrollDown from "../../framer-effects/framer-scroll-down";

function AboutUsOverzicht() {
    return (
        <>
            <div className="flex flex-col w-full items-center justify-center my-16 gap-8 px-8">
                <FramerScrollDown className="flex flex-col w-4/6 items-center justify-center mb-16 text-center">
                    <h2 className="text-xl font-bold my-4">Over ons</h2>
                    <p className="text-lg">
                        Een plaats, die helemaal gewijd is aan schoonheid en een gevoel van welbevinden.
                        Een atmosfeer, die vriendelijk en vertrouwd is.
                        Geniet van zachte aanrakingen, ervaar de weldaad van verzorging en tank nieuwe energie.
                    </p>
                </FramerScrollDown>
                <div className="flex flex-col items-start justify-center w-full gap-x-8
                                lg:flex-row xl:w-2/3">
                <FramerScrollDown className="flex flex-col w-full lg:w-1/2 items-center justify-center my-8 text-center">
                    <img className="w-full rounded-lg shadow-2xl max-w-xl"
                        src={Anja} alt=""></img>
                    <div className="flex flex-col items-center justify-center w-full m-4 max-w-xl">
                        <h3 className="text-xl font-semibold highlight">Anja</h3>
                        <h4 className="text-lg">Zaakvoerster bij <br/>schoonheidsinstituut Lapoo</h4>
                        <hr className="solid bg-gray-300 w-[96%] mt-4"></hr>
                        <blockquote className="font-serif italic text-md mt-4 text-start">
                            “Al meer dan 34 jaar oefen ik mijn beroep als schoonheidsspecialiste uit en ben nog steeds even gedreven om mijn klanten tot in de puntjes te verzorgen zodat zij met een goed gevoel het instituut verlaten. Mijn sterkste eigenschappen zijn mijn perfectionisme en spontaniteit. Door het nog steeds bijscholen van nieuwe technieken ben ik gespecialiseerd in meerdere behandelingen in de cosmetica.“
                        </blockquote>
                    </div>
                </FramerScrollDown>
                <FramerScrollDown className="flex flex-col w-full lg:w-1/2 items-center justify-center my-8 text-center">
                    <img className="w-full rounded-lg shadow-2xl max-w-xl"
                        src={Janice} alt=""></img>
                    <div className="flex flex-col items-center justify-center w-full m-4 max-w-xl">
                        <h3 className="text-xl font-semibold highlight">Janice</h3>
                        <h4 className="text-lg">Werkneemster bij <br/>schoonheidsinstituut Lapoo</h4>
                        <hr className="solid bg-gray-300 w-[96%] mt-4"></hr>
                        <blockquote className="font-serif italic text-md mt-4 text-start">
                            “Al van jongs af aan ben ik enorm geïnteresseerd in alles wat met schoonheid te maken heeft. Mijn sterkste eigenschappen zijn nauwkeurigheid, oog voor details en streven naar een constante verbetering. Zo zullen mijn klanten nooit het instituut verlaten eer ik zelf tevreden ben van de behandeling en het eindresultaat.“
                        </blockquote>
                    </div>
                </FramerScrollDown>
                </div>
            </div>
        </>
    );
}

export default AboutUsOverzicht;