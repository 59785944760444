function Footer() {
    return (
        <div className="flex flex-row justify-center w-full bg-[#6a8240] py-20 text-white text-sm">
            <div className="w-5/6 xxl:w-4/6">
                <div className="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-7 gap-16">
                    <div className="flex flex-col items-center lg:items-start justify-start col-span-1">
                        <div className="flex flex-col items-start justify-start gap-3">
                            <span className="text-base font-semibold">Lapoo</span>
                            <a className="underline-offset-2 decoration-2 hover:underline decoration-lapooLichtGroen" target="_blank" href="http://maps.google.com/maps?q=Mezenstraat+15,+9070+Destelbergen" rel="noreferrer"><span>Mezenstraat 15<br />9070 Destelbergen</span></a>
                            
                        </div>
                    </div>
                    <div className="flex flex-col items-center lg:items-start justify-start col-span-1">
                        <div className="flex flex-col items-start justify-start gap-3">
                            <span className="text-base font-semibold">Contactgegevens</span>
                            <span className="font-semibold">Telefoon<br />0474 67 80 26</span>
                            <span className="font-semibold">Email<br />lapoo@skynet.be</span>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-start col-span-1 lg:col-span-2 xl:col-span-3 gap-3">
                        <span className="text-base font-semibold">Volg ons op onze social media</span>
                        <div className="flex flex-row items-center justify-start gap-1">
                            <a target="_blank" className="hover:-translate-y-1 transition-all duration-200 hover:bg-lapooLichtGroen rounded-lg" href="https://www.facebook.com/schoonheidsinstituutlapoo/" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 m-1" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
                                </svg>
                            </a>
                            <a target="_blank" className="hover:-translate-y-1 transition-all duration-200 hover:bg-lapooLichtGroen rounded-lg" href="https://www.instagram.com/schoonheidsinstituut_lapoo/" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 m-1" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <rect x="4" y="4" width="16" height="16" rx="4"></rect>
                                    <circle cx="12" cy="12" r="3"></circle>
                                    <line x1="16.5" y1="7.5" x2="16.5" y2="7.501"></line>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-col items-center lg:items-start justify-start">
                        <div className="flex flex-col items-start justify-start gap-3">
                            <span className="text-base font-semibold">Sitemap</span>
                            <div className="flex flex-row items-center justify-start gap-16">
                                <div className="flex flex-col items-start justify-center">
                                    <a className="hover:font-semibold hover:scale-105" href="/">Home</a>
                                    <a className="hover:font-semibold hover:scale-105" href="/over-ons">Over ons</a>
                                    <a className="hover:font-semibold hover:scale-105" href="/behandelingen">Behandelingen</a>
                                    <a className="hover:font-semibold hover:scale-105" href="/gallerij">Gallerij</a>
                                    <a className="hover:font-semibold hover:scale-105" href="/cadeaubon">Cadeaubon</a>
                                    <a className="hover:font-semibold hover:scale-105" href="/contact">Contact</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center lg:items-start justify-start gap-3">
                        <span className="text-base font-semibold">Blijf op de hoogte van nieuwe acties!</span>
                        <a target="_blank" href="https://schoonheidsinstituut-lapoo.us20.list-manage.com/subscribe/post?u=7db3a068cdd99246a43da653c&amp;id=495cb71d90&amp;f_id=00fc4fe6f0" className="hover:cursor-pointer text-center min-w-[64px] border border-white rounded-md p-1 hover:-translate-y-1 transition-all duration-200" rel="noreferrer">Schrijf je in voor de nieuwsbrief →</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;