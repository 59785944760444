import './App.css';
import Homepage from './pages/homepage';
import { Routes, Route, Navigate } from "react-router-dom";
import BehandelingenPage from './pages/behandelingenpage';
import CadeaubonPage from './pages/cadeaubonpage';
import ContactPage from './pages/contactpage';
import Navbar from './components/navbar/navbar';
import Footer from './components/footer/footer';
import GalleryPage from './pages/gallery-page';
import AboutUsPage from './pages/about-us-page';

function App() {
  return (
    <div className="flex flex-col items-center justify-center scrollbar bg-white w-full">
      <Navbar />
      <Routes>
        <Route path="/over-ons" element={<AboutUsPage />} />
        <Route path="/behandelingen" element={<BehandelingenPage />} />
        <Route path="/gallerij" element={<GalleryPage />} />
        <Route path="/cadeaubon" element={<CadeaubonPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/" element={<Homepage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </div >
  );
}

export default App;
