import ContactUsForm from "../components/contact-us-form/contact-us-form";
import HeroSectionContainer from "../shared/components/section-container/hero-section-container";

function ContactPage() {
    return (
        <div className="flex flex-col justify-center items-center w-full">
            <HeroSectionContainer>
                <ContactUsForm />
            </HeroSectionContainer>
        </div>
    );
}

export default ContactPage;