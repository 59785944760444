import BehandelingenOverzicht from "../components/behandelingen/behandelingen-overzicht";
import BehandelingenHero from "../components/behandelingen/hero/behandelingen-hero";
import HeroSectionContainer from "../shared/components/section-container/hero-section-container";
import SectionContainer from "../shared/components/section-container/section-container";

function BehandelingenPage() {

    return (
        <div className="flex flex-col items-center justify-center w-full">
            <HeroSectionContainer>
                <BehandelingenHero />
            </HeroSectionContainer>
            <BehandelingenOverzicht />
        </div>
    );
}

export default BehandelingenPage;