import Lightbox, { ImagesListType } from "react-spring-lightbox";
import { useState } from 'react';

function ImageGallery({images} : {images : ImagesListType}) {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [IsLightboxOpen, setIsLightboxOpen] = useState(false);

    const gotoPrevious = () => {
        if (currentImageIndex > 0) {
            setCurrentImageIndex(currentImageIndex - 1);
        }
        else {
            setCurrentImageIndex(images.length-1);
        }
    }

    const gotoNext = () => {
        if (currentImageIndex + 1 < images.length) {
            setCurrentImageIndex(currentImageIndex + 1);
        }
        else {
            setCurrentImageIndex(0);
        }
    }

    const onImageClick = (index: number) => {
        setCurrentImageIndex(index);
        setIsLightboxOpen(!IsLightboxOpen)
    }

    return (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 p-5">
            {images.map((image, index) => {
                return <img key={index} className="border-4 box-border border-transparent hover:cursor-pointer hover:border-lapooLichtGroen ease-linear duration-150" src={image.src} alt={image.alt} onClick={() => onImageClick(index)} />
            })}
            <Lightbox
                isOpen={IsLightboxOpen}
                onPrev={gotoPrevious}
                onNext={gotoNext}
                images={images}
                currentIndex={currentImageIndex}
                /* Add your own UI */
                renderHeader={() => (
                    <div className="flex flex-row justify-end w-full">
                        <button className="z-50 w-14 rounded-full" onClick={() => setIsLightboxOpen(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x stroke-white" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </button>
                    </div>
                )}
                // renderFooter={() => (<CustomFooter />)}
                renderPrevButton={() => (
                    <button className="z-50 w-14 rounded-full" onClick={() => gotoPrevious()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className=" icon icon-tabler icon-tabler-chevron-left stroke-white" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <polyline points="15 6 9 12 15 18"></polyline>
                        </svg>
                    </button>
                )}
                renderNextButton={() => (
                    <button className="z-50 w-14 rounded-full" onClick={() => gotoNext()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right stroke-white" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <polyline points="9 6 15 12 9 18"></polyline>
                        </svg>
                    </button>
                )}
                // renderImageOverlay={() => (<ImageOverlayComponent >)}

                /* Add styling */
                className="bg-black bg-opacity-90"

                /* Handle closing */
                // onClose={handleClose}

                /* Use single or double click to zoom */
                // singleClickToZoom

                /* react-spring config for open/close animation */
                pageTransitionConfig={{
                    from: { transform: "scale(0.75)", opacity: 0 },
                    enter: { transform: "scale(1)", opacity: 1 },
                    leave: { transform: "scale(0.75)", opacity: 0 },
                    config: { mass: 1, tension: 400, friction: 32 }
                }}
            />
        </div>
    );
}

export default ImageGallery;