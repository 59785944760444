import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { behandelingen } from "../../behandelingen";

type Inputs = {
    voornaamVan: string,
    achternaamVan: string,
    email: string,
    telefoon: string,
    voornaamVoor: string,
    achternaamVoor: string,
    soortBon: string,
    bedrag: number,
    behandeling: string,
    access_key: string,
    botcheck: boolean,
    from_name: string,
    meerdereBehandelingen1: string,
    meerdereBehandelingen2: string,
    meerdereBehandelingen3: string,
    subject: string,
};

function CadeaubonForm() {
    const { register, handleSubmit, reset, formState: { errors, isSubmitSuccessful, isDirty, isValid } } = useForm<Inputs>({ mode: "onTouched" });
    const [isSuccess, setIsSuccess] = useState(false);

    const [selectValue, setSelectValue] = useState("");
    const onSelectChange = (event: any) => {
        const value = event.target.value;
        setSelectValue(value);
    }

    const onSubmit: SubmitHandler<Inputs> = async (data, e) => {
        var body =  {};

        data.subject = `${data.voornaamVan} ${data.achternaamVan} wil een cadeaubon via de website.`;

        if (data.soortBon === "bedrag") {
            data.behandeling = "/";
            data.meerdereBehandelingen1 = "/";
            data.meerdereBehandelingen2 = "/";
            data.meerdereBehandelingen3 = "/";
        }
        else if (data.soortBon === "behandeling") {
            data.bedrag = 0;
            data.meerdereBehandelingen1 = "/";
            data.meerdereBehandelingen2 = "/";
            data.meerdereBehandelingen3 = "/";
        }
        else if (data.soortBon === "multiBehandeling") {
            data.bedrag = 0;
            data.behandeling = "/";
            if (data.meerdereBehandelingen3 === "") {
                data.meerdereBehandelingen3 = "/";
            }
        }

        await fetch("https://api.web3forms.com/submit", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(data, null, 2),
        })
            .then(async (response) => {
                let json = await response.json();
                if (json.success) {
                    setIsSuccess(true);
                    e!.target.reset();
                    reset();
                } else {
                    setIsSuccess(false);
                }
            })
            .catch((error) => {
                setIsSuccess(false);
                console.log(error);
            });
    };

    return (
        <div className="flex flex-col xl:flex-row items-center justify-center w-full">
            <div className="w-full flex flex-col-reverse items-center justify-center min-h-screen 
                xl:w-3/4 xl:flex-row">
                <div className="flex flex-col w-3/4 items-center justify-center m-4 gap-2
                    xl:items-start">
                    {!isSubmitSuccessful && (
                        <form className="flex flex-col w-full shadow-2xl p-8 gap-2" onSubmit={handleSubmit(onSubmit)}>
                            { /** WEB3FORMS CONFIG */}
                            <input type="hidden" value="d5aada3a-4d00-4808-ba76-bf0267a5b78b" {...register("access_key")}></input>
                            <input type="checkbox" id="" style={{ display: "none" }} className="hidden" {...register("botcheck")}></input>
                            <input type="hidden" value="Lapoo website" {...register("from_name")}></input>
                            <input type="hidden" {...register("subject")}></input>

                            <div className="flex flex-col w-full items-center justify-center gap-2">
                                <h1 className="font-bold text-xl w-full text-left">Bestel een cadeaubon!</h1>
                                <p className="text-md text-start w-full">
                                    Vul het onderstaande formulier in om een cadeaubon te bestellen. U kan kiezen voor een cadeaubon ter waarde van één of meerdere behandelingen of voor een bepaald bedrag. Alle cadeaubonnen dienen te worden betaald bij afhaling in het Instituut.
                                </p>
                            </div>
                            <h3 className="text-start w-full font-semibold text-lg mt-4">Contactgegevens</h3>
                            <div className="flex flex-col w-full gap-4
                                xl:flex-row">
                                <div className="flex flex-col w-full gap-1
                                xl:w-1/2">
                                    <label className="after:content-['*'] after:ml-0.5 after:text-red-500" htmlFor="voornaamVan">Voornaam</label>
                                    <input type="text" className="bg-gray-50 h-10 border-gray-300 focus:ring-0 focus:border-lapooLichtGroen" {...register("voornaamVan", { required: true, maxLength: 35 })}></input>
                                    {errors.voornaamVan?.type === 'required' && <span className="text-red-500">Dit veld is verplicht</span>}
                                    {errors.voornaamVan?.type === 'maxLength' && <span className="text-red-500">Maximum van 35 letters bereikt</span>}
                                </div>
                                <div className="flex flex-col w-full gap-1
                                xl:w-1/2">
                                    <label className="after:content-['*'] after:ml-0.5 after:text-red-500" htmlFor="achternaamVan">Achternaam</label>
                                    <input type="text" className="bg-gray-50 h-10 border-gray-300 focus:ring-0 focus:border-lapooLichtGroen" {...register("achternaamVan", { required: true, maxLength: 35 })}></input>
                                    {errors.achternaamVan?.type === 'required' && <span className="text-red-500">Dit veld is verplicht</span>}
                                    {errors.achternaamVan?.type === 'maxLength' && <span className="text-red-500">Maximum van 35 letters bereikt</span>}
                                </div>
                            </div>

                            <div className="flex flex-col w-full gap-4
                            xl:flex-row">
                                <div className="flex flex-col w-full gap-1
                                xl:w-1/2">
                                    <label className="after:content-['*'] after:ml-0.5 after:text-red-500" htmlFor="email">Email
                                    </label>
                                    <input type="email" className="bg-gray-50 h-10 border-gray-300 focus:ring-0 focus:border-lapooLichtGroen" {...register("email", { required: true, pattern: /[^@\s]+@[^@\s]+\.[^@\s]+/ })}></input>
                                    {errors.email?.type === 'required' && <span className="text-red-500">Dit veld is verplicht</span>}
                                    {errors.email?.type === 'pattern' && <span className="text-red-500">Geef een geldig email adres in</span>}
                                </div>
                                <div className="flex flex-col w-full gap-1
                                xl:w-1/2">
                                    <label className="after:content-['*'] after:ml-0.5 after:text-red-500" htmlFor="telefoon">Telefoon
                                    </label>
                                    <input type="tel" className="bg-gray-50 h-10 border-gray-300 focus:ring-0 focus:border-lapooLichtGroen" {...register("telefoon", { required: true, pattern: /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/ })}></input>
                                    {errors.telefoon?.type === 'required' && <span className="text-red-500">Dit veld is verplicht</span>}
                                    {errors.telefoon?.type === 'pattern' && <span className="text-red-500">Geef een geldig telefoonnummer in</span>}
                                </div>
                            </div>

                            <h3 className="text-start w-full font-semibold text-lg  mt-4">Ontvanger</h3>

                            <div className="flex flex-col w-full gap-4
                                xl:flex-row">
                                <div className="flex flex-col w-full gap-1
                                xl:w-1/2">
                                    <label className="after:content-['*'] after:ml-0.5 after:text-red-500" htmlFor="voornaamVoor">Voornaam</label>
                                    <input type="text" className="bg-gray-50 h-10 border-gray-300 focus:ring-0 focus:border-lapooLichtGroen" {...register("voornaamVoor", { required: true, maxLength: 35 })}></input>
                                    {errors.voornaamVoor?.type === 'required' && <span className="text-red-500">Dit veld is verplicht</span>}
                                    {errors.voornaamVoor?.type === 'maxLength' && <span className="text-red-500">Maximum van 35 letters bereikt</span>}
                                </div>
                                <div className="flex flex-col w-full gap-1
                                xl:w-1/2">
                                    <label className="after:content-['*'] after:ml-0.5 after:text-red-500" htmlFor="achternaamVoor">Achternaam</label>
                                    <input type="text" className="bg-gray-50 h-10 border-gray-300 focus:ring-0 focus:border-lapooLichtGroen" {...register("achternaamVoor", { required: true, maxLength: 35 })}></input>
                                    {errors.achternaamVoor?.type === 'required' && <span className="text-red-500">Dit veld is verplicht</span>}
                                    {errors.achternaamVoor?.type === 'maxLength' && <span className="text-red-500">Maximum van 35 letters bereikt</span>}
                                </div>
                            </div>

                            <h3 className="text-start w-full font-semibold text-lg  mt-4">Inhoud</h3>

                            <div className="flex flex-col w-full gap-4
                                xl:flex-row">
                                <div className="flex flex-col w-full gap-1
                                xl:w-1/2">
                                    <label className="after:content-['*'] after:ml-0.5 after:text-red-500" htmlFor="soortBon">Soort cadeaubon</label>
                                    <select  className="bg-gray-50 h-10 border-gray-300 focus:ring-0 focus:border-lapooLichtGroen" {...register("soortBon", { required: true, onChange: (e) => onSelectChange(e) })}>
                                        <option value="" disabled selected>-- Kies een optie --</option>
                                        <option value="bedrag">Cadeaubon voor een bepaald bedrag</option>
                                        <option value="behandeling">Cadeaubon voor een bepaalde behandeling</option>
                                        <option value="multiBehandeling">Cadeaubon voor meerdere behandelingen</option>
                                    </select>
                                    {errors.soortBon?.type === 'required' && <span className="text-red-500">Dit veld is verplicht</span>}
                                </div>
                            </div>
                            <div className="flex flex-col w-full gap-4
                                xl:flex-row">
                                {selectValue === "bedrag" &&
                                    <div className="flex flex-col w-full gap-1
                                        xl:w-1/2">
                                        <label className="after:content-['*'] after:ml-0.5 after:text-red-500" htmlFor="bedrag">Bedrag</label>
                                        <input type="number" className="bg-gray-50 h-10 border-gray-300 focus:ring-0 focus:border-lapooLichtGroen" {...register("bedrag", { required: true, min: 1, max: 1000 })}></input>
                                        {errors.bedrag?.type === 'required' && <span className="text-red-500">Dit veld is verplicht</span>}
                                        {errors.bedrag?.type === 'min' && <span className="text-red-500">Bedrag moet minstens €1 zijn</span>}
                                        {errors.bedrag?.type === 'max' && <span className="text-red-500">Voor een bedrag van €1000 of meer moet u telefonisch contact opnemen:  0474/67 80 26</span>}
                                    </div>
                                }
                                {selectValue === "behandeling" &&
                                    <div className="flex flex-col w-full gap-1
                                        xl:w-1/2">
                                        <label className="after:content-['*'] after:ml-0.5 after:text-red-500" htmlFor="behandeling">Behandeling</label>
                                        <select className="bg-gray-50 h-10 border-gray-300 focus:ring-0 focus:border-lapooLichtGroen" {...register("behandeling", { required: true })}>
                                            {behandelingen.map((b, index) => {
                                                return <optgroup key={index} label={b.category}>
                                                    {b.values.map((value, index) => {
                                                        return <option key={index} value={value.name + " - €" + value.price}>{value.name} - €{value.price}</option>
                                                    })}
                                                </optgroup>
                                            })}
                                        </select>
                                        {errors.behandeling && <span className="text-red-500">Dit veld is verplicht</span>}
                                    </div>
                                }
                                {selectValue === "multiBehandeling" &&
                                    <div className="flex flex-col w-full gap-1
                                    xl:w-1/2">
                                    
                                    <label className="after:content-['*'] after:ml-0.5 after:text-red-500" htmlFor="meerdereBehandelingen1">Behandeling 1</label>
                                    <select className="bg-gray-50 h-10 border-gray-300 focus:ring-0 focus:border-lapooLichtGroen" {...register("meerdereBehandelingen1", { required: true })}>
                                        {behandelingen.map((b, index) => {
                                            return <optgroup key={index} label={b.category}>
                                                {b.values.map((value, index) => {
                                                    return <option key={index} value={value.name + " - €" + value.price}>{value.name} - €{value.price}</option>
                                                })}
                                            </optgroup>
                                        })}
                                    </select>
                                    {errors.meerdereBehandelingen1 && <span className="text-red-500">Dit veld is verplicht</span>}
                                    <label className="after:content-['*'] after:ml-0.5 after:text-red-500" htmlFor="meerdereBehandelingen2">Behandeling 2</label>
                                    <select className="bg-gray-50 h-10 border-gray-300 focus:ring-0 focus:border-lapooLichtGroen" {...register("meerdereBehandelingen2", { required: true })}>
                                        {behandelingen.map((b, index) => {
                                            return <optgroup key={index} label={b.category}>
                                                {b.values.map((value, index) => {
                                                    return <option key={index} value={value.name + " - €" + value.price}>{value.name} - €{value.price}</option>
                                                })}
                                            </optgroup>
                                        })}
                                    </select>
                                    {errors.meerdereBehandelingen2 && <span className="text-red-500">Dit veld is verplicht</span>}
                                    <label className="" htmlFor="meerdereBehandelingen3">Behandeling 3</label>
                                    <select className="bg-gray-50 h-10 border-gray-300 focus:ring-0 focus:border-lapooLichtGroen" {...register("meerdereBehandelingen3")}>
                                        <option value="" selected disabled>-- Geen --</option>
                                        {behandelingen.map((b, index) => {
                                            return <optgroup key={index} label={b.category}>
                                                {b.values.map((value, index) => {
                                                    return <option key={index} value={value.name + " - €" + value.price}>{value.name} - €{value.price}</option>
                                                })}
                                            </optgroup>
                                        })}
                                    </select>
                                    <p className="text-red-500">Wil je meer dan 3 behandelingen cadeau doen? Neem dan telefonisch contact op: 0474/67 80 26</p>
                                </div>
                                }
                            </div>
                            <div>
                                <input disabled={!isDirty || !isValid} className={!isDirty || !isValid ? 
                                    "rounded-sm bg-lapooGroen text-white px-4 py-2 mt-4 hover:cursor-not-allowed" 
                                    : "rounded-sm bg-lapooLichtGroen text-white hover:bg-lapooGroen px-4 py-2 mt-4 hover:cursor-pointer"} type="submit"></input>
                            </div>
                        </form>
                    )}
                    {isSubmitSuccessful && isSuccess && (
                        <>
                            <div className="flex flex-col items-center justify-center text-center text-white rounded-md w-full">
                                <svg
                                    width="100"
                                    height="100"
                                    className="text-green-300"
                                    viewBox="0 0 100 100"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M26.6666 50L46.6666 66.6667L73.3333 33.3333M50 96.6667C43.8716 96.6667 37.8033 95.4596 32.1414 93.1144C26.4796 90.7692 21.3351 87.3317 17.0017 82.9983C12.6683 78.6649 9.23082 73.5204 6.8856 67.8586C4.54038 62.1967 3.33331 56.1283 3.33331 50C3.33331 43.8716 4.54038 37.8033 6.8856 32.1414C9.23082 26.4796 12.6683 21.3351 17.0017 17.0017C21.3351 12.6683 26.4796 9.23084 32.1414 6.88562C37.8033 4.5404 43.8716 3.33333 50 3.33333C62.3767 3.33333 74.2466 8.24998 82.9983 17.0017C91.75 25.7534 96.6666 37.6232 96.6666 50C96.6666 62.3768 91.75 74.2466 82.9983 82.9983C74.2466 91.75 62.3767 96.6667 50 96.6667Z"
                                        stroke="currentColor"
                                        strokeWidth="3"
                                    />
                                </svg>
                                <h3 className="py-5 text-2xl text-green-500">Wij hebben uw bericht ontvangen!</h3>
                            </div>
                        </>
                    )}

                    {isSubmitSuccessful && !isSuccess && (
                        <div className="flex flex-col items-center justify-center text-center text-white rounded-md">
                            <svg
                                width="97"
                                height="97"
                                viewBox="0 0 97 97"
                                className="text-red-400"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M27.9995 69C43.6205 53.379 52.3786 44.621 67.9995 29M26.8077 29L67.9995 69M48.2189 95C42.0906 95 36.0222 93.7929 30.3604 91.4477C24.6985 89.1025 19.554 85.6651 15.2206 81.3316C10.8872 76.9982 7.44975 71.8538 5.10454 66.1919C2.75932 60.53 1.55225 54.4617 1.55225 48.3333C1.55225 42.205 2.75932 36.1366 5.10454 30.4748C7.44975 24.8129 10.8872 19.6684 15.2206 15.335C19.554 11.0016 24.6985 7.56418 30.3604 5.21896C36.0222 2.87374 42.0906 1.66667 48.2189 1.66667C60.5957 1.66667 72.4655 6.58333 81.2172 15.335C89.9689 24.0867 94.8856 35.9566 94.8856 48.3333C94.8856 60.7101 89.9689 72.58 81.2172 81.3316C72.4655 90.0833 60.5957 95 48.2189 95Z"
                                    stroke="CurrentColor"
                                    strokeWidth="3"
                                />
                            </svg>

                            <h3 className="text-2xl text-red-400 py-4">
                                Oeps, er is iets verkeerd gelopen!
                            </h3>
                            <button className="px-4 py-2 focus:outline-none text-white bg-red-400 rounded-lg" onClick={() => reset()}>
                                Probeer opnieuw
                            </button>
                        </div>
                    )}
                </div>
            </div >
        </div>
    );
}

export default CadeaubonForm;