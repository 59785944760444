import StarSVG from "../../shared/components/svgs/star";
import StarEmptySVG from "../../shared/components/svgs/star-empty";
import StarHalfSVG from "../../shared/components/svgs/star-half";
import FramerScrollDown from "../../framer-effects/framer-scroll-down";

function HomeReviews() {

    const generateStars: any = (num: number) => {
        let stars = [];

        for (let i = 1; i <= Math.floor(num); i++) {
            stars.push(<StarSVG />);
        }

        if (!Number.isInteger(num)) {
            stars.push(<StarHalfSVG />);
        }

        for (let i = stars.length; i < 5; i++) {
            stars.push(<StarEmptySVG />);
        }

        return stars;
    }

    const reviews = [
        {
            name: "Ginette Lonneville",
            stars: generateStars(5),
            description: "Zeer vriendelijk onthaal, perfecte behandeling en makkelijk bereikbaar.😁😁😁"
        },
        {
            name: "Caroline Van Lancker",
            stars: generateStars(5),
            description: "Al sinds 2004 mijn favoriete schoonheidsinstituut. Goede producten, professionele uitvoering, perfecte timing en een zeer persoonlijke en vriendelijke aanpak. Een aanrader 👍👍👍"
        },
        {
            name: "Leonie Delneste",
            stars: generateStars(5),
            description: "Anja kent haar vak! supersympathiek en altijd vriendelijk onthaald."
        },
        {
            name: "Sybille Vandewalle",
            stars: generateStars(5),
            description: "Heerlijke verwennerij. Superhartelijke ontvangst. Vakmanschap."
        },
        {
            name: "Inge Van Beversluys",
            stars: generateStars(5),
            description: "Vriendelijk en professioneel."
        },
    ]

    return (
        <div className="flex flex-col items-center justify-center w-full gap-8">
            <FramerScrollDown className="flex flex-col items-center justify-center w-full md:w-2/3 lg:w-1/3 text-center">
                <h2 className="text-xl">Ontdek wat onze <span className="highlight">klanten</span> zeggen over ons!</h2>
            </FramerScrollDown>
            <div className="flex flex-row flex-wrap items-start justify-center w-full gap-4
                            ">
                {reviews.map((obj, ind) => {
                            return <FramerScrollDown delay={ind*0.15} className="flex flex-col items-center justify-center text-center text-base gap-2 my-8 w-full md:max-w-lg">
                                <h3 className="font-semibold">{obj.name}</h3>
                                <div className="flex gap-0.5">{obj.stars}</div>
                                <p className="w-1/2">{obj.description}</p>
                            </FramerScrollDown>
                        })}
            </div>
        </div>
    );
}

export default HomeReviews;