import AboutUsOverzicht from "../components/about-us/about-us-overzicht";
import AboutUsHero from "../components/about-us/hero/about-us-hero";
import HeroSectionContainer from "../shared/components/section-container/hero-section-container";
import SectionContainer from "../shared/components/section-container/section-container";

function AboutUsPage() {
    return (
        <div className="flex flex-col items-center w-full">
            <HeroSectionContainer>
                <AboutUsHero />
            </HeroSectionContainer>
            <SectionContainer>
                <AboutUsOverzicht />
            </SectionContainer>
        </div>
    );
}

export default AboutUsPage;